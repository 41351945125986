import { POSTAny,GETAny,POSTBody,postOriginal,PUT,DELETE,DOWNLOAD,FORM} from '@/api/apiFn';

// sim卡管理 VO
export interface SiminfoVO {
  id?: number // ID
  simid: string // SIM卡
  holdid: number // 所属客户
  channelId:number
  company: string // 供货商
  msisdn?: string // MSISDN
  iccid: string // CCID
  operators?: string // 运营商：移动、联通、电信
  dataLimit?: number // 套餐总量(KB)，返回-1表示后向套餐
  status?: string // 卡状态：未知、待激活、已激活、已停机、已到期、预销户、已销户
  activeDate?: Date // 激活日期。如果卡未激活，则返回空字符串
  serviceStartDate?: Date // 服务开始日期。如果卡未激活，则返回空字符串
  serviceEndDate?: Date // 服务截止日期。如果卡未激活，则返回空字符串
  packageName?: string // 主套餐
  remark?: string // 备注
  payment?: number // 支付方式
}


// sim卡管理 API
export default {
  // 查询sim卡管理分页
  getSiminfoPage: async (params: any) => {
    return await GETAny({ url: `/admin-api/gps/siminfo/page`, params })
  },

  // 查询sim卡管理详情
  getSiminfo: async (id: number) => {
    return await GETAny({ url: `/admin-api/gps/siminfo/get?id=` + id })
  },

  // 新增sim卡管理
  createSiminfo: async (params: any) => {
   
    return await POSTBody( '/admin-api/gps/siminfo/create', params)
  },

  importSiminfo: async (params: any) => {
   
    return await FORM( '/admin-api/gps/siminfo/import', params)
  },

  // 修改sim卡管理
  updateSiminfo: async (params: any) => {
    return await POSTBody( '/admin-api/gps/siminfo/update', params )
  },

  // 删除sim卡管理
  deleteSiminfo: async (ids: string) => {
    return await POSTAny({ url: `/admin-api/gps/siminfo/delete?ids=` + ids })
  },

  // 导出sim卡管理 Excel
  exportSiminfo: async (params) => {
    return await DOWNLOAD({ url: `/admin-api/gps/siminfo/export-excel`, params })
  }
}