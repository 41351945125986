import "core-js/modules/es.array.push.js";
import { reactive, toRefs, defineAsyncComponent, nextTick, computed, watch } from "vue";
import { useStore } from "vuex";
import { useDebounce } from "@/hooks/core/useDebounce";
import API from "@/api/siminfo/index";
import { useMessage } from "@/hooks/web/useMessage";
import { Search } from "@element-plus/icons-vue";
import { ElMessageBox } from "element-plus";
import { formatDate } from '@/utils/formatTime';
export default {
  name: "SimCard",
  components: {
    IssuedMode: defineAsyncComponent(() => import('@/views/live/IssuedMode.vue')),
    // 自定义组件
    BasicTemplate: defineAsyncComponent(() => import("@/components/BasicTemplateTablePage/index.vue")),
    TreeSelect: defineAsyncComponent(() => import("@/components/TreeSelect/TreeSelect.vue")),
    TypeSelect: defineAsyncComponent(() => import("@/components/TypeSelect/TypeSelect.vue")),
    StudentDistribution: defineAsyncComponent(() => import("./components/SimCardDistribution.vue")),
    ImportModal: defineAsyncComponent(() => import("@/components/ImportModal/ImportModal.vue")),
    AddStudent: defineAsyncComponent(() => import("./components/AddSimCard.vue")),
    PromptBox: defineAsyncComponent(() => import("@/components/PromptBox/index.vue")),
    ExportSelect: defineAsyncComponent(() => import("./components/ExportSimCardSelect.vue")),
    RenewalOper: defineAsyncComponent(() => import("../authority/components/RenewalOper.vue"))
  },
  setup() {
    const store = useStore();
    const message = useMessage();
    const refData = reactive({
      renewalOperRefs: null,
      sign: '',
      issuseVisible: false,
      nodeData: null,
      errorList: [],
      errorTitle: '提示',
      refExportSelect: null,
      refAddRemark: null,
      promptBoxRefs: null,
      AddStudentShow: false,
      isClickTree: false,
      Height: 0,
      basicTemplateRefs: null,
      filterHideRefs: null,
      studentDistributionRefs: null,
      botherSetRefs: null,
      InstructionShow: false,
      activeCardId: null,
      activeInfo: {},
      importModalTitle: "",
      importShow: false,
      botherSetShow: false,
      studentInfoShow: false,
      familyBindingShow: false,
      dismissBindShow: false,
      show: false,
      onoff: false,
      loading: false,
      total: 0,
      current: 1,
      searchForm: {
        childFlag: true,
        holdid: null,
        simid: "",
        iccid: "",
        status: "",
        activationTime: [],
        serviceDate: [],
        createTime: []
      },
      activeOrg: {
        id: null,
        name: "",
        activeValue: null
      },
      type: 1,
      tableData: [],
      tableColumns: [{
        type: "selection",
        title: "勾选",
        align: "center",
        key: "checkbox",
        width: 30,
        visible: true
      }, {
        title: "序号",
        align: "center",
        key: "index",
        width: 80,
        slots: {
          customRender: "index"
        },
        visible: true
      }, {
        title: "流量卡号",
        align: "center",
        sortable: true,
        width: 140,
        key: "simid",
        visible: true
      }, {
        title: '所属单位',
        align: 'center',
        key: 'holdname',
        visible: true
      }, {
        title: "iccid",
        align: "center",
        sortable: true,
        width: 140,
        key: "iccid",
        visible: true
      }, {
        title: "卡商",
        width: 140,
        align: "center",
        key: "company",
        visible: true
      }, {
        title: "运营商",
        align: "center",
        sortable: true,
        width: 100,
        key: "operators",
        visible: true
      }, {
        title: "卡状态",
        align: "center",
        sortable: true,
        width: 100,
        key: "status",
        visible: true
      }, {
        title: "导入时间",
        width: 160,
        sortable: true,
        align: "center",
        key: "createTime",
        visible: true
      }, {
        title: "激活时间",
        align: "center",
        width: 160,
        key: "activeDate",
        visible: true
      }, {
        title: "服务开始",
        align: "center",
        width: 160,
        key: "serviceStartDate",
        visible: true
      }, {
        title: "服务截止",
        align: "center",
        width: 160,
        key: "serviceEndDate",
        visible: true
      }, {
        title: "备注",
        align: "center",
        width: 160,
        key: "remark",
        slots: {
          customRender: 'remark'
        },
        visible: true
      }, {
        title: "套餐流量",
        align: "center",
        width: 160,
        key: "dataLimit",
        visible: true
      }, {
        title: "套餐已用",
        align: "center",
        width: 160,
        key: "dataUsage",
        visible: true
      }, {
        title: "主套餐",
        align: "center",
        width: 160,
        key: "packageName",
        visible: true
      }, {
        title: "同步时间",
        align: "center",
        width: 160,
        key: "syncTime",
        visible: true
      }, {
        title: "同步结果",
        align: "center",
        width: 160,
        key: "syncResult",
        visible: true
      }, {
        title: "同步接口ID",
        align: "center",
        width: 160,
        key: "channelId",
        slots: {
          customRender: 'channelId'
        },
        visible: true
      }, {
        fixed: "right",
        title: "操作",
        width: 180,
        align: "center",
        key: "opereat",
        slots: {
          customRender: "opereat"
        },
        visible: true
      }],
      activeOrgId: null,
      imeiList: [],
      objectIds: [],
      simids: [],
      activeValue: null
    });
    const id = computed(() => store.state.treeData.length ? store.state.treeData[0][0].id : null);
    const userType = computed(() => {
      var _store$state$userInfo;
      return (_store$state$userInfo = store.state.userInfo) === null || _store$state$userInfo === void 0 ? void 0 : _store$state$userInfo.userType;
    });
    const pageSize = computed(() => {
      var _store$state$configDa;
      return store.state.configData[0] ? Number((_store$state$configDa = store.state.configData[0]) === null || _store$state$configDa === void 0 ? void 0 : _store$state$configDa.userConf) : 50;
    }); // table 每页数量过多会导致卡顿
    //查询
    const onSearch = async () => {
      refData.loading = true;
      const params = {
        childFlag: refData.searchForm.childFlag ? 1 : 0,
        holdid: refData.searchForm.holdid,
        simid: refData.searchForm.simid,
        iccid: refData.searchForm.iccid,
        status: refData.searchForm.status,
        serviceStartDate: refData.searchForm.serviceDate && refData.searchForm.serviceDate.length ? refData.searchForm.serviceDate[0] + " 00:00:00" : "",
        serviceEndDate: refData.searchForm.serviceDate && refData.searchForm.serviceDate.length ? refData.searchForm.serviceDate[1] + " 23:59:59" : "",
        pageSize: pageSize.value,
        pageNo: refData.current
      };
      try {
        const {
          data,
          msg,
          code
        } = await API.getSiminfoPage(params);
        refData.tableData = [];
        //console.log("getobjectpage: "+JSON.stringify(data.list)); 
        if (code > 0) {
          message.warning(msg);
        } else {
          refData.total = data.total;
          if (data.list) {
            refData.tableData = data.list.map(item => {
              item.checkbox = false;
              item.createTime = formatDate(item.createTime);
              return item;
            });
            //refData.tableData = Object.keys(data).length?[{...data}]:[];
          }
        }
      } catch (error) {
        //console.log("error" + JSON.stringify(error))
        //message.warning("123");
      } finally {
        refData.loading = false;
      }
    };
    watch(() => id.value, val => {
      nextTick(() => {
        val && (refData.searchForm.holdid = val, debounceOnSearch());
      });
    });
    nextTick(() => {
      refData.searchForm.holdid = store.state.treeData.length ? store.state.treeData[0][0].id : null, debounceOnSearch();
    });
    const [debounceOnSearch] = useDebounce(onSearch, 200);
    const currentChange = val => {
      refData.current = val;
      debounceOnSearch();
    };
    const pageSizeChange = val => {
      debounceOnSearch();
    };
    // 结构树回调
    const handleClick = data => {
      refData.activeOrg.activeValue = data.typeId;
      refData.searchForm.holdid = data.id;
      refData.activeOrg.name = data.name;
      refData.activeOrg.id = data.id;
      refData.isClickTree = true;
      debounceOnSearch();
    };
    // 确认导入按钮
    const handleConfirm = async (file, useStatus) => {
      //type : 0为设备库存导入   1为流量卡导入  2为禁用解锁设置
      const type = refData.importModalTitle == "流量卡导入" ? 1 : refData.importModalTitle == "禁用解锁设置" ? 2 : 0;
      //请求参数
      const params = type == 1 ? {
        file,
        holdid: refData.searchForm.holdid,
        isUpdate: useStatus
      } : type == 2 ? {
        file,
        useStatus
      } : type == 0 ? {
        file,
        holdid: refData.searchForm.holdid,
        isUpdate: useStatus
      } : {
        file,
        holdid: refData.searchForm.holdid
      };
      //请求接口
      const url = type == 1 ? "importSiminfo" : type == 2 ? "importUseStatus" : "objectinfoImportExcel";
      try {
        refData.loading = true;
        const {
          code,
          msg,
          data
        } = await API[url](params);
        console.log("code" + code + msg);
        if (code == 0) {
          if (data) {
            refData.errorList = type === 0 ? data.hasOwnProperty('failureNames') ? data.failureNames : data : data;
            if (refData.errorList.length > 0) {
              refData.errorTitle = "导入数据错误提示";
              refData.promptBoxRefs.visible = true;
            } else {
              refData.errorList = type === 0 ? data.hasOwnProperty('createNames') ? data.createNames : data : data;
              refData.errorTitle = "导入成功数据";
              if (refData.errorList.length > 0) {
                refData.promptBoxRefs.visible = true;
              }
            }
          }
          debounceOnSearch();
          message.success("上传成功" + data.createNames);
          refData.importShow = false;
        } else {
          message.warning(msg);
        }
      } catch (error) {
        message.error(error.msg);
      } finally {
        refData.loading = false;
      }
    };
    // 打开导入弹框
    const handleShowImprot = (name, type) => {
      if (!refData.searchForm.holdid || !refData.isClickTree) {
        message.warning("请先选择组织");
      } else {
        refData.type = type;
        refData.importModalTitle = name;
        refData.importShow = true;
      }
    };
    //查看设备详情
    const getStudentInfo = async id => {
      refData.activeCardId = id;
      refData.studentInfoShow = true;
    };
    //删除设备
    const handleDel = async (id, name, state) => {
      if (state != 1) {
        ElMessageBox.confirm(`是否确认删除“${name}”该流量卡`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(async () => {
          const {
            code,
            msg
          } = await API.deleteSiminfo(id.toString());
          code == 0 && debounceOnSearch();
          message[code == 0 ? "success" : "warning"](code == 0 ? "删除成功" : msg);
        }).catch(() => {
          // ElMessage.info("已取消退出");
        });
      } else {
        message.warning("该流量卡已绑定，请先解绑");
        return;
      }
    };
    const [debounceOnDelte] = useDebounce(handleDel, 200);
    // 打开导出弹框
    const handleShowExport = () => {
      refData.refExportSelect.visible = true;
    };
    //清空
    const handleReset = () => {
      const holdid = refData.searchForm.holdid;
      refData.searchForm = {
        childFlag: false,
        holdid,
        simid: "",
        iccid: "",
        status: "",
        activationTime: [],
        serviceDate: [],
        createTime: []
      };
      message.success("查询条件已清空");
    };
    //查询条件显示隐藏
    const handleShowHide = () => {
      refData.onoff = true;
      // refData.basicTemplateRefs.handleInit();
    };
    //设备分配-上课免打扰
    const handleClickType = async type => {
      const dom = type == 1 ? "studentDistributionRefs" : "botherSetRefs";
      const show = type == 1 ? "show" : "botherSetShow";
      refData[show] = true;
    };
    const handleBatchDelete = async () => {
      const str = `是否确认批量删除“${refData.imeiList}”设备`;
      ElMessageBox.confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const {
          msg,
          code
        } = await API.deleteSiminfo(refData.objectIds.join());
        code == 0 && debounceOnSearch();
        message[code == 0 ? "success" : "warning"](code == 0 ? "删除成功" : msg);
      }).catch(() => {
        // ElMessage.info("已取消退出");
      });
    };
    //勾选table
    const handleSelectionChange = data => {
      refData.imeiList = data.map(item => item.simid);
      refData.objectIds = data.map(item => item.id);
    };
    //点击修改
    const handleEnit = objectid => {
      refData.activeCardId = objectid;
      refData.AddStudentShow = true;
    };
    const handleShowForm = (objectid, remark) => {
      refData.activeCardId = objectid;
      refData.AddStudentShow = true;
    };
    //续费
    const handleRenewal = importSize => {
      refData.renewalOperRefs.visible = true;
      refData.renewalOperRefs.cardIds = [];
      for (let i = 0; i < importSize; i++) {
        refData.renewalOperRefs.cardIds.push(i);
      }
      refData.renewalOperRefs.imei = '';
      refData.renewalOperRefs.expireDate = '';
    };
    // 续费导入报错信息
    const handleError = errorList => {
      refData.errorList = errorList;
      refData.promptBoxRefs.visible = true;
    };
    // 初始化函数调用
    return {
      ...toRefs(refData),
      currentChange,
      pageSizeChange,
      debounceOnSearch,
      handleClick,
      handleBatchDelete,
      handleConfirm,
      handleShowImprot,
      getStudentInfo,
      debounceOnDelte,
      handleReset,
      handleShowForm,
      handleShowHide,
      handleClickType,
      handleSelectionChange,
      handleEnit,
      pageSize,
      Search,
      handleShowExport,
      handleError
    };
  }
};